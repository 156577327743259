import { isEmail, isLength } from "validator";
import messages from "../common/messages";
function isNumberSpaceOnly(input) {
    var regex = /^[0-9]+$/;
    if (regex.test(input)) {
        return true;
    } else {
        return false;
    }
}
export default function validateHomePage({  agentid,
    access_token,
    property_address,
    city,
    first_name,
    last_name,
    Email_id,
    country_code,
    phone,
    funding_amount,
    termconditonCheck,
    enableTermStatus

}) {
     //  console.log("country code .....",country_code,phone);
    let errors = {};
    if (!property_address) {
        errors.property_address = messages["property_address.required"];
    } 
 
      if (!city) {
        errors.city = messages["city.required"];
    }
    if (!first_name) {
        errors.first_name = messages["first_name.required"];
    }
    if (!last_name) {
        errors.last_name = messages["last_name.required"];
    }
    
    if (!country_code) {
        errors.country_code = messages["country_code.required"];
    }
  
    if (!funding_amount) {
        errors.funding_amount = messages["funding_amount.required"];
    }
    if (funding_amount <= 1){
        errors.funding_amount=messages["funding_amount.invalid"] ;
    }
    if( enableTermStatus==="1"){
        if (termconditonCheck === false) {
            errors.termconditonCheck = messages["termconditonCheck.required"];
        }
    }
   
      
    
    if (!Email_id) {
        errors.Email_id = messages["Email_id.required"];
    } else if (!isEmail(Email_id) && !isLength(Email_id, { min: 10, max: 50 })) {
        errors.Email_id = messages["Email_id.invalid"];
    }
   
    // if (!phone) {
    //     errors.phone = messages["phone.required"];
    //   } else if (!isLength(phone, { min: 10, max: 14 })) {
    //     errors.phone = messages["phone.invalid"];
    //   } else if( !(phone.match('[0-9]{10}')) ){
    //     errors.phone = messages["phone.invalid"];
    //   } else if(!(phone.replace(/[^0-9+]/g, ''))){
    //     errors.phone = messages["phone.invalid"];
    //   } else if (country_code ==="+61"){
    //     if (!isLength(phone, { min: 12, max: 12 })) {
    //         errors.phone = messages["phone.lengthAus"];
    //     }
     // }
    
     
        if(!phone){
            errors.phone = messages["phone.required"];

        }/*else if(!(phone.match('[0-9]{10}'))){
            errors.phone = messages["phone.invalid"];

        } */else{
           let phone2 =  phone.replaceAll(' ','');
           if (phone2.substring(0, 1) === '0') {
            phone2 = phone2.slice(1);
            }
            if (phone2.substring(0, 1) !== '+') {
                phone2 = country_code + phone2;
            }
            //console.log("phone...",reciveValue);
           // console.log("phone...",phone.replaceAll(' ','')); 
            if(country_code ==="+61")
            {        
                //if (!isLength(reciveValue, { min: 12, max: 12 }) || !isLength(phone, { min: 12, max: 12 })) {
                if(!phone2.startsWith(country_code + '4')) {
                    errors.phone = messages["phone.lengthAus"];
                }    
                else if (!isLength(phone2, { min: 12, max: 12 })) {    
                   errors.phone = messages["phone.lengthAus"];
                }
            } else if(country_code ==="+64")
            {
                if(!phone2.startsWith(country_code + '2')) {
                    errors.phone = messages["phone.lengthAus"];
                }    
                else if (!isLength(phone2, { min: 11, max: 13 })) 
                {
                    errors.phone = messages["phone.lengthNew"];
                }
            } 
      }
    return errors; 
}