import React, { Component } from "react";
import { connect } from "react-redux";
//import profile_img from '../../img/profile-picture.png'
import jwt from 'jwt-decode'
//import LoginForm from "./LoginForm";

class HomeHeader extends Component {

    constructor() {
        super();
        this.state = {
            toggleSidebar:false,
            firstName:"",
            lastName:""
        }
    }
   
    render() { 
           
        return (
            <React.Fragment>
             <nav className="navbar">
    <div className="container">
        {this.props.reciveData.company_logo !== "" && (
     
        <img style={{width:"200px"}} src={this.props.reciveData.base_url + this.props.reciveData.company_logo} />
        )}

    </div>
  </nav>
            </React.Fragment>
        );
    }
}



const mapStateToProps = (state) => {
    {}  
};

HomeHeader.propTypes = {
   
};

export default connect(mapStateToProps, {
   
})(HomeHeader);

//export default HomeHeader;