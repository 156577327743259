import React, { Component } from "react";
import { connect } from "react-redux";
import HomeHeader from "./HomeHeader";
import HomeFooter from "./HomeFooter";
import * as Api from "../../../../data/ApiRequest";
import * as path from "../../../../data/ApiList";
import { CountryCodeOption } from "./CountryCodeOption";
import { stateList } from './Statelist'
import { toast } from 'react-toastify';
import Loading from "../../../common/Loading";
import validateHomePage from "../../../validation_rules/HomePageValidation";
// import Modal  from 'react-bootstrap-modal'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
class HomePage extends Component {
  intState = {
    access_token: "",
    property_address: "",
    city: "",
    first_name: "",
    last_name: "",
    Email_id: "",
    country_code: "",
    phone: "",
    funding_amount: "",
    state: "",
    termconditonCheck: false,
    enableTermStatus: "",

  }
  constructor() {
    super();
    this.state = {
      toggleSidebar: false,
      firstName: "",
      lastName: "",
      reciveConsumerData: {},
      directConsumState: this.intState,
      reciveStatus: false,
      errors: {},
      checkTermCondition: true,
      show: false,
      reciveStatusCode: 200,
      checkValidationStatus: false,
      dtoc_middleware_status: "",


    }
  }


  componentDidMount = async () => {
    try {
      const newPath = this.props.location?.pathname;
      const queryString = this.props.location.search;
      const splitUrl = newPath.split('/postproperty/');

      // console.log("this is log for splitUrl ", splitUrl[1]);
      const fullUrl = `${window.location.origin}${newPath}${queryString}`;
      const url = new URL(fullUrl);
      const params = new URLSearchParams(url.search);

      // Extract query parameters
      const access_token = splitUrl[1];
      const address = params.get('address') || "";
      const suburb = params.get('suburb') || "";
      const state = params.get('state') || "";
      const first = params.get('first') || "";
      const last = params.get('last') || "";
      const email = params.get('email') || "";
      const phone1 = params.get('phone') || "";
      const amount = params.get('amount') || "";

      this.setState(prevState => ({
        directConsumState: {
          ...prevState.directConsumState,
          access_token,
          property_address: address,
          city: suburb,
          state,
          first_name: first,
          last_name: last,
          Email_id: email,
          phone: phone1,
          funding_amount: amount,
          country_code: "",
          enableTermStatus: "",
        }
      }), async () => {
        const pathRecive = this.props.location?.pathname;
        const splitUrl = pathRecive.split('/postproperty/');
        try {
          const res1 = await Api.getRequest(path.GET_CONSUMER_DETAIL_URL + splitUrl[1]);
          document.title = res1.data.title || "";
          this.setState(prevState => ({
            reciveConsumerData: res1.data,
            reciveStatusCode: res1.status,
            dtoc_middleware_status: res1.data.dtoc_middleware_status,
            directConsumState: {
              ...prevState.directConsumState,
              enableTermStatus: res1.data.enableTermStatus,
              country_code: (res1.data.country === 'AU') ? "+61" : "+64",
            }
          }), () => {

            const { access_token, property_address, city, first_name, last_name, Email_id, country_code, phone, funding_amount, enableTermStatus } = this.state.directConsumState;

            let errors = validateHomePage({
              access_token,
              property_address,
              city,
              first_name,
              last_name,
              Email_id,
              country_code,
              phone,
              funding_amount,
              enableTermStatus
            });

            if (Object.entries(errors).length > 0) {
              // console.log("There are errors found in your code...", Object.entries(errors).length, errors);
              this.setState({ checkValidationStatus: true, errors });
            } else {
              console.log("All looks good....");
              // console.log("this is direct status", this.state.dtoc_middleware_status);

              // Check if dtoc_middleware_status is "1" before making the post request
              if (this.state.dtoc_middleware_status === "1") {
                this.setState({ reciveStatus: true }, () => {
                  Api.postRequest2(path.ADD_DIRECT_CONSUMER_DETAIL_URL, this.state.directConsumState).then((res) => {
                    this.setState({ reciveStatus: true })
                    if (!res.data.error) {

                      setTimeout(() => {
                        window.location.href = `${res.data.data}`;
                        //this.cardTemplate();
                      }, 1000);
                      //  this.setState({ reciveStatus: true })
                    }
                  }).catch(error => {
                    const message = error.response.data.message?.detail || error.response.data.message;
                    toast.warn(message, {
                      position: toast.POSITION.TOP_CENTER
                    });
                    this.setState({ reciveStatus: false });
                  });

                })

              } else {
                console.log("dtoc_middleware_status is not '1', skipping postRequest2");
              }
            }
          });
        } catch (error) {
          this.setState({ reciveStatusCode: error.response.status });
          this.setState({ reciveStatus: false });
        }
      });
    } catch (error) {
      console.error("Error in componentDidMount:", error);
      this.setState({ reciveStatus: false });
    }
  }


  cardTemplate = () => {
    return <section className="formArea mBtm">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-7">
            <div className="formBox mx-auto mb-5" >
              {/* <h2 className="text-center">{this.state.reciveConsumerData.title} <br />
                      </h2>
                      <hr className="hrStyle mx-auto" />  */}
              <p>Thank you for selecting {this.state.reciveConsumerData.title} to sell your property.
                To help get your campaign under way. {this.state.reciveConsumerData.title} has partnered with Campaign Flow to provide flexible payment options for marketing costs.
                You can decide to pay now or pay later and make your payment in only a few simple steps. </p>
              <div><p className="text-center"><b>Please wait, you will be redirected shortly...</b></p></div>
            </div>
          </div>

        </div>

      </div>
    </section>
  }
  onHandleChange = (e) => {

    const { errors } = this.state;
    delete errors[e.target.name];
    this.setState({
      ...this.state,
      directConsumState: {
        ...this.state.directConsumState,
        [e.target.name]: e.target.value,

      }
    })
  }
  onHandleChangeChecked = (e) => {
    const { errors } = this.state;
    delete errors[e.target.name];
    this.setState({
      ...this.state,
      directConsumState: {
        ...this.state.directConsumState,
        termconditonCheck: e.target.checked,
      }
    })
  }
  showLoader = () => {
    this.setState({
      reciveStatus: true
    })

  }

  onSubmitConsumer = (e) => {
    e.preventDefault();
    const {
      access_token,
      property_address,
      city,
      first_name,
      last_name,
      Email_id,
      country_code,
      phone,
      funding_amount,
      termconditonCheck,
      enableTermStatus,
    } = this.state.directConsumState;

    let errors = validateHomePage({
      access_token,
      property_address,
      city,
      first_name,
      last_name,
      Email_id,
      country_code,
      phone,
      funding_amount,
      termconditonCheck,
      enableTermStatus,
    });

    if (Object.entries(errors).length > 0) {
      this.setState({ errors });
    } else {
      this.setState({ reciveStatus: true }, () => {
        Api.postRequest2(path.ADD_DIRECT_CONSUMER_DETAIL_URL, this.state.directConsumState)
          .then((res) => {
            if (!res.data.error) {
              setTimeout(() => {
                this.cardTemplate();
                window.location.href = `${res.data.data}`;
              }, 1000);
            }
          })
          .catch((error) => {
            const message = error.response.data.message?.detail || error.response.data.message;
            toast.warn(message, {
              position: toast.POSITION.TOP_CENTER,
            });
            this.setState({ reciveStatus: false });
          })
          .finally(() => {
          });
      });
    }
  };




  handleClose = () => this.setState({
    show: false
  });
  handleShow = (e) => {
    e.preventDefault()
    this.setState({
      show: true
    });
  }

  onKeyPress1 = (e) => {
    const { errors } = this.state;
    delete errors[e.target.name];
    let trimValue
    if (/^0/.test(e.target.value)) {
      trimValue = e.target.value.replace(/^0/, "")
      this.setState({
        ...this.state,
        directConsumState: {
          ...this.state.directConsumState,
          [e.target.name]: e.target.value.replace(/^0/, "")

        }
      })
    } else {
      this.setState({
        ...this.state,
        directConsumState: {
          ...this.state.directConsumState,
          [e.target.name]: e.target.value,

        }
      })
    }
  }
  render() {
    if (this.state.reciveConsumerData.country == "AU") {
      var countryCode = CountryCodeOption[0].code;
      var countryName = CountryCodeOption[0].name;
    }
    else {
      var countryCode = CountryCodeOption[1].code;
      var countryName = CountryCodeOption[1].name;
    }
    const bannerStyle = {
      backgroundImage: `url(${this.state.reciveConsumerData.base_url + this.state.reciveConsumerData.banner_image})`,
      backgroundRepeat: " no-repeat",
      backgroundSize: " cover",
      paddingTop: "20rem ",
      paddingBottm: "10rem ",

    }
    const buttonColor = {
      backgroundColor: `${this.state.reciveConsumerData.color_code}`,
      fontWeight: "700",
      width: "50%",
      paddingLeft: "25px",
      paddingRight: "25px",
      color: "#000"
    }
    const hrColor = {
      width: "190px",
      border: `4px solid ${this.state.reciveConsumerData.color_code}`,
      borderRadius: "5px"
    }
    const bulletColor = {
      color: `${this.state.reciveConsumerData.color_code}`,
    }
    const boldColor = {
      color: `${this.state.reciveConsumerData.color_code}`,
    }




    return (
      <React.Fragment>
        {/* {console.log("this is recive status...12.",this.state.reciveConsumerData.footer_content)
        } */}
        {this.state.reciveStatusCode === 200 ? (
          <>
            {/* {this.state.reciveStatus && this.cardTemplate()} */}
            <HomeHeader reciveData={this.state.reciveConsumerData} />
            <section className="heroArea">
              <div>
                <div className="bannerImg" style={bannerStyle}></div>
              </div>
            </section>
            {this.state.reciveStatus === false ?
              <section className="formArea mBtm">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-sm-7">
                      <div className="formBox mx-auto">
                        <h2 className="text-center">{this.state.reciveConsumerData.title} <br />
                        </h2>
                        <hr className="hrStyle mx-auto" style={hrColor} />
                        <form onSubmit={this.onSubmitConsumer}>
                          {/* Property Address */}
                          <div className="mb-3">
                            <label htmlFor="propertyAddress" className="form-label">Property Address</label>
                            <input className="form-control" onChange={this.onHandleChange} type="text" id="propertyAddress" name="property_address" value={this.state.directConsumState.property_address} />
                            <div className="errorMsg">{this.state.errors["property_address"]}</div>
                          </div>

                          {/* Suburb */}
                          <div className="mb-3">
                            <label htmlFor="suburb" className="form-label">Suburb</label>
                            <input className="form-control" onChange={this.onHandleChange} type="text" id="suburb" name="city" value={this.state.directConsumState.city} />
                            <div className="errorMsg">{this.state.errors["city"]}</div>
                          </div>

                          {/* State (if AU) */}
                          {this.state.reciveConsumerData.country === "AU" && (
                            <div className="mb-3">
                              <label htmlFor="state" className="form-label">State</label>
                              <select className="form-control" onChange={this.onHandleChange} name="state" value={this.state.directConsumState.state}>
                                <option value="">Select State</option>
                                {stateList.map(data => (
                                  <option key={data.state_code} value={data.state_code}>{data.name}</option>
                                ))}
                              </select>
                            </div>
                          )}

                          {/* First Name */}
                          <div className="mb-3">
                            <label htmlFor="inputFirstName" className="form-label">First Name</label>
                            <input className="form-control" onChange={this.onHandleChange} type="text" id="inputFirstName" name="first_name" value={this.state.directConsumState.first_name} />
                            <div className="errorMsg">{this.state.errors["first_name"]}</div>
                          </div>

                          {/* Last Name */}
                          <div className="mb-3">
                            <label htmlFor="inputLastName" className="form-label">Last Name</label>
                            <input className="form-control" onChange={this.onHandleChange} type="text" id="inputLastName" name="last_name" value={this.state.directConsumState.last_name} />
                            <div className="errorMsg">{this.state.errors["last_name"]}</div>
                          </div>

                          {/* Email */}
                          <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Email</label>
                            <input type="text" className="form-control" onChange={this.onHandleChange} id="exampleInputEmail1" name="Email_id" value={this.state.directConsumState.Email_id} />
                            <div className="errorMsg">{this.state.errors["Email_id"]}</div>
                          </div>

                          {/* Phone */}
                          <div className="mb-3">
                            <label htmlFor="inputPhone" className="form-label">Phone</label>
                            <div className="d-flex align-items-center">
                              <label className="form-label phlabel">{countryCode + " " + countryName}</label>
                              <input type="hidden" name="country_code" value={countryCode} />
                              <span className="w-100">
                                <input className="form-control"
                                  type="tel" onChange={this.onHandleChange}
                                  id="inputPhone" name="phone"
                                  maxLength="15"
                                  value={this.state.directConsumState.phone} />
                                <div className="errorMsg mr-5">{this.state.errors["phone"]}</div>
                              </span>
                            </div>

                          </div>

                          {/* Funding Amount */}
                          <div className="mb-3">
                            <label htmlFor="fundingAmt" className="form-label">Funding Amount Requested</label>
                            <div className="input-group">
                              <span className="input-group-text">$</span>
                              <input type="number"
                                // min="1" // Set min value to enforce greater than 0
                                //step="1" // Allow decimal inputs
                                onChange={this.onHandleChange}
                                id="fundingAmt" className="form-control"
                                name="funding_amount"
                                value={this.state.directConsumState.funding_amount} />
                            </div>
                            <div className="errorMsg">{this.state.errors["funding_amount"]}

                              {/* {this.state.directConsumState.funding_amount <= 0 && this.state.directConsumState.funding_amount !== "" && (
                                    <span className="text-danger">Amount must be greater than 0.</span>
                                  )} */}

                            </div>
                          </div>

                          {/* Terms and Conditions */}
                          {this.state.directConsumState.enableTermStatus === "1" && (
                            <div className="mb-3 form-check">
                              <input type="checkbox" className="form-check-input" id="exampleCheck1" onChange={this.onHandleChangeChecked} />
                              <label className="form-check-label" htmlFor="exampleCheck1">I have read, understood and agree to the <a href="#" onClick={this.handleShow}>Terms and conditions</a> above</label>
                              <div className="errorMsg">{this.state.errors["termconditonCheck"]}</div>
                            </div>
                          )}

                          <button type="submit" className="btn" style={buttonColor}>Get Started</button>
                        </form>
                      </div>
                    </div>

                    {/* Right Side Content */}
                    <div className="col-sm-4 align-self-center">
                      <div className="right-side">
                        {this.state.reciveConsumerData?.page_content && this.state.reciveConsumerData.page_content !== "null" ? (
                          <div dangerouslySetInnerHTML={{ __html: this.state.reciveConsumerData.page_content }}></div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              : this.cardTemplate()}
            {/* Modal for Terms and Conditions */}
            <Modal show={this.state.show} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Term & Condition</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.reciveConsumerData?.term_and_conditions && this.state.reciveConsumerData.term_and_conditions !== "undefined" && this.state.reciveConsumerData.term_and_conditions.trim() ? (
                  <div dangerouslySetInnerHTML={{ __html: this.state.reciveConsumerData.term_and_conditions }}></div>
                ) : (
                  <div>No terms and conditions available</div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>Close</Button>
              </Modal.Footer>
            </Modal>
            {this.state.reciveStatus === false &&
              <HomeFooter data={this.state.reciveConsumerData.footer_content} />
            }
          </>
        ) : (
          <div className="center-data">
            <div className="no-match container">
              <div className="error-msg">
                <h1>ERROR {this.state.reciveStatusCode}</h1>
                <h2>ACCESS FORBIDDEN</h2>
                <p>The page you are trying to access is not authorized to you. Please contact Administrator.</p>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}



const mapStateToProps = (state) => {
  { }
};

HomePage.propTypes = {

};

export default connect(mapStateToProps, {

})(HomePage);

//export default HomeHeader;